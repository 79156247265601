import React from 'react';
import SectionRichText from '@/components/sections/SectionRichText/SectionRichText';
import { OfficeLocationsTypes } from './OfficeLocations.types';
import * as styles from './OfficeLocations.module.scss';

export default function OfficeLocations({ title, officeLocations }: OfficeLocationsTypes) {
  if (!officeLocations.length) return null;
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{title}</h2>
      <ul className={styles.locationList}>
        {officeLocations.map(location => (
          <li key={location._uid} className={styles.card}>
            <p className={styles.country}>{location.country}</p>
            <p className={styles.region}>{location.city}</p>
            {location.details && <SectionRichText body={location?.details} className={styles} />}
            {location?.phone && <p className={styles.phone}>{location?.phone}</p>}
          </li>
        ))}
      </ul>
    </div>
  );
}
